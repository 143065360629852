import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="about" />
      <h2>hu am i? the abridged edition</h2>
      <p>
        hey there! my name is catherine hu, and i study tech/enviro stuff @ ucla. welcome to my <i>always-a-work-in-progress</i>{" "}
        site!
      </p>
      <p>
        i first got into tech because i was drawn to its
        potential to build communities and bring people together. i'm excited to
        use my education and experiences to explore my creativity and passions
        for product and social impact.
      </p>
      <p>
        check out the projects + work i've done <a href="experience">here</a>!
      </p>
      <h2>hobbies</h2>
      <ul>
        <li>writing short stories</li>
        <li>making bad puns</li>
        <li>developing recipes</li>
        <li>reading to procrastinate (memoirs, sci fi/fantasy, wikipedia, historical fiction, etc.)</li>
        <li>overusing the Oxford comma</li>
        <li>appreciating trees</li>
        <li>compiling my life into lists (the best books, my favorite coffee shops, 78215 to-do lists)</li>
        <li>stress cleaning</li>
        <li>restructuring her college four-year plan</li>
      </ul>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
